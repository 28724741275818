@import '~bootstrap-css-only/css/bootstrap.min.css';
@import 'assets/styles/Antd/antd.cleanui';
@import 'assets/styles/CleanUI/cleanui';
@import 'assets/styles/Bootstrap/bootstrap.cleanui';
@import 'assets/styles/Chartist/chartist.cleanui';
@import 'assets/styles/Nprogress/nprogress.cleanui';
@import 'assets/styles/Rcdrawer/rcdrawer.cleanui';
@import '~c3/c3.min.css';

// input, select, textarea {
//   min-height: 40px;
// }
.statusArea .ant-row {
  margin: 0;
  padding: 0 !important;
}
.statusArea,
.ant-form-vertical .statusArea .ant-form-item-label {
  text-align: right;
}
.ant-select-selection--multiple {
  min-height: 40px;
}
.ant-select-selection--multiple .ant-select-selection__choice {
  min-height: 32px;
  display: inline-flex;
  align-items: center;
}

.common-input .ant-select-selection--single {
  position: relative;
  height: 40px;
  cursor: pointer;
  display: inline-flex;
  width: 100%;
  vertical-align: middle;
}

.common-input .ant-select-selection-selected-value {
  height: 100%;
  display: inline-flex !important;
  align-items: center;
}

.ant-calendar-picker {
  display: block !important;
}

.ant-select-selection--single {
  height: 40px !important;
}

.view-order-cards .card {
  min-height: 315px;
}

.edit-wrap {
  margin: 0 0 20px;
}
.btn {
  border-radius: 0;
  border: none;
  height: auto;
  padding: 5px 20px;
  font-size: 15px;
  font-weight: 500;
}
.highlighted-btn {
  background: #219ee0;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
}

.user-details-card .card-body {
  padding: 15px 10px;
}
.user-details-card p strong {
  margin: 0 3px 0 0;
}
.btn-margins {
  margin: 0 10px 0 0;
}
.ant-tabs-bar {
  margin: 0;
}
.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  padding: 16px 16px 6px !important;
  margin: 0 10px 0 0;
}

.common-card-body {
  padding-top: 10px;
}

.order-single-table {
  border: 1px solid #ddd;
  padding: 15px 15px 0;
  margin: 0 0 15px;
}
.order-id {
  background: #f7f7f7;
  padding: 7px 15px 6px;
  display: inline-block;
}
.track-btn-wraper {
  text-align: right;
}
.track-btn {
  background: #0190fe;
  display: inline-block;
  padding: 6px 15px 4px;
  color: #fff;
}
.track-btn:hover {
  background: #0d7fd6;
}
.order-single-table-header {
  padding: 0 0 10px;
}
.ordered-product-head {
  font-weight: 700;
}
.order-single-table-content {
  border-top: 1px solid #ddd;
  padding: 10px 0;
}
.ordered-product-img {
  display: flex;
  align-items: center;
  height: 100%;
}
.ordered-product-details {
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}
.ordered-product-price {
  font-weight: 700;
  font-size: 18px;
  display: inline-flex;
  margin: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.steps-ordered-product {
  margin: auto;
  padding: 20px 0;
}

.thumbnail-area {
  display: inline-block;
  width: 4.28rem;
  height: 4.28rem;
  border: 1px solid #e4e9f0;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #fff;
  text-align: center;

  img {
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    vertical-align: middle;
  }
}

.badge {
  font-size: 95%;
  text-transform: capitalize;
}

.ant-tabs .form-group {
  padding-right: 1px;
}

.pull-right > *:nth-child(2) {
  margin-left: 15px;
}

.thumbnail-area .image-view {
  transition: all 0.2s ease-in-out;
}

.thumbnail-area .image-view:hover {
  transform: scale(1.2);
}

.card-header .header-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.card-header .header-content .add-item {
  margin-left: auto;
}

.ant-dropdown-trigger:hover {
  cursor: pointer;
}

.ql-toolbar .ql-snow,
.ql-container .ql-snow {
  border: 1px solid #e4e9f0;
}

.ql-toolbar .ql-snow {
  border-radius: 4px 4px 0 0;
}

.ql-container .ql-snow {
  border-radius: 0 0 4px 4px;
}

.ant-form-item-control.has-error .ant-form-explain {
  padding: 5px 0 0 0;
}

.link-resume:hover {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
  opacity: 0.8;
}

// [class*="ginger-module-highlighter-mistake-type-"], [class*="ginger-module-highlighter-mistake-type-"]::after {
//   background: none;
// }

// Responsive CSS Starts Here
@media (max-width: 1199px) {
  .steps-ordered-wrapper {
    order: 4;
  }
}

@media (max-width: 991px) {
  .view-order-cards .card {
    min-height: auto;
  }
}

@media (max-width: 767px) {
  .attribute-wrap {
    margin: 0 0 15px;
  }
  .order-head {
    font-size: 14px;
  }
}

@media (max-width: 575px) {
  .ordered-product-price-wrapper {
    order: 2;
  }
  .ordered-product-details-wrapper {
    order: 3;
  }
  .ordered-product-details {
    margin: 5px 0 0;
    border-top: 1px dashed #ddd;
    padding: 3px 0 0;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal {
    display: block;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
    min-height: 48px;
    overflow: hidden;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: block;
    overflow: visible;
  }
  .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    margin: 0;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal
    > .ant-steps-item:not(:last-child)
    > .ant-steps-item-tail {
    display: block;
  }

  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 16px;
    width: 1px;
    height: 100%;
    padding: 38px 0 6px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 12px;
    padding: 30px 0 6px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail::after {
    width: 1px;
    height: 100%;
  }
  .ant-steps-item-title::after {
    width: 0 !important;
    height: 0 !important;
  }
}
// Responsive CSS Ends Here
